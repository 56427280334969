<template>
  <v-col>
    <v-row
      align-content="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        v-if="personImages.length > 0"
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 16 : 20"
        >
          mdi-face-recognition
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $t('deconve.person.facesOfThisPerson') }}
        </div>
      </div>
      <v-skeleton-loader
        v-else
        type="heading"
        style="flex: 1"
        max-width="300px"
      />
      <square-button
        icon-name="mdi-information-outline"
        :icon-color="showPersonFacesInfo? 'white' : 'neutral'"
        :color="showPersonFacesInfo? 'info' : 'transparentBackground'"
        menu-disabled
        :tooltip-message="$t('deconve.info')"
        :outlined="!showPersonFacesInfo"
        fab
        small
        @clicked="showPersonFacesInfo = !showPersonFacesInfo"
      />
    </v-row>
    <face-list
      data-dd-privacy="hidden"
      :faces="imageWithFaces"
      :is-loading="personImages.length === 0"
      @onFaceSelected="selectImage"
    />
    <v-row
      v-if="!isLoading && personImages.length > 0 && imageWithFaces.length === 0"
      class="mt-8"
    >
      <alert
        type="error"
        color="warn"
      >
        <div>
          {{ $t('deconve.person.alerts.infos.noPersonFaces') }}
        </div>
      </alert>
    </v-row>
    <v-row
      v-if="showPersonFacesInfo"
      class="mt-8"
    >
      <alert
        type="info"
        color="info"
      >
        <div>
          {{ $t('deconve.person.alerts.infos.personFaces') }}
        </div>
      </alert>
    </v-row>
    <v-row
      v-if="!isLoading"
      align="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 20 : 24"
        >
          mdi-image-outline
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ personImages.length }} {{ $t('deconve.images') }}
        </div>
      </div>
    </v-row>
    <v-row
      v-else
      class="mb-2"
    >
      <v-skeleton-loader
        type="heading"
        style="flex: 1"
        max-width="500"
      />
    </v-row>
    <v-row v-if="showImageCropper">
      <image-cropper-editor
        v-if="!isLoading"
        :images="personImages"
        :index="selectedImageIndex"
        :is-edit-mode="false"
        data-dd-privacy="hidden"
        @close="showImageCropper=false"
        @changed="updateSelectedImageIndex"
      />
    </v-row>
    <image-list
      data-dd-privacy="hidden"
      :is-edit-mode="false"
      :score-status="true"
      :is-loading="isLoading"
      :images="personImages"
      @set-crop-image="selectImage"
    />
  </v-col>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import ImageList from '@/components/ImageList.vue';
import ImageCropperEditor from '@/components/ImageCropperEditor.vue';
import FaceList from '@/components/FaceList.vue';
import SquareButton from '@/components/SquareButton.vue';
import Alert from '@/components/Alert.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'ProfileImages',
  components: {
    ImageList,
    ImageCropperEditor,
    FaceList,
    SquareButton,
    Alert,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    images: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      showImageCropper: false,
      showPersonFacesInfo: false,
      selectedImageIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      personImages: 'faceid/personImages',
    }),
    imageWithFaces() {
      const faces = [];

      this.personImages.forEach((img, i) => {
        if (img.info?.faces.length > 0) {
          faces.push({
            image: img.originalImage || img.mediumImage || img.thumbnailImage,
            name: img.originalName || img.mediumName,
            info: img.info,
            originalHeight: img.originalHeight,
            originalWidth: img.originalWidth,
            index: i,
          });
        }
      });
      return faces;
    },
  },
  methods: {
    selectImage(index) {
      this.selectedImageIndex = index;
      this.showImageCropper = true;
    },
    updateSelectedImageIndex(index) {
      this.selectedImageIndex = index;
    },
  },
};
</script>
