<template>
  <v-container
    class="pa-2"
    style="overflow-y: auto; height: 100%; width: 100%;"
    fluid
  >
    <v-card
      v-if="expireAt"
      id="personContainer"
      color="warn lighten-4"
      tile
      class="d-flex align-center"
      elevation="0"
      data-cy="person-viewer-trash-message"
      min-height="68"
    >
      <v-card-text class="d-flex align-center justify-center">
        <v-icon
          class="mr-2"
          color="warn"
        >
          {{ icons.alertCircleOutline }}
        </v-icon>
        <span class="warn--text text-body-2 text-sm-body-1">
          {{
            $t(
              'deconve.person.personInTrashMessage',
              [$moment(expireAt).format('DD/MM/YYYY HH:mm:ss')]
            )
          }}h
        </span>
      </v-card-text>
    </v-card>

    <v-card
      v-else-if="!isPersonEnabled && !isLoading"
      id="personContainer"
      color="warn lighten-4"
      tile
      class="d-flex align-center"
      elevation="0"
      min-height="68"
    >
      <v-card-text class="d-flex align-center justify-center">
        <v-icon
          class="mr-2"
          color="warn"
        >
          {{ icons.alertCircleOutline }}
        </v-icon>
        <span class="warn--text text-body-2 text-sm-body-1">
          {{ reasonForDeactivation }}
        </span>
      </v-card-text>
    </v-card>

    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>
        {{ icons.chevronLeft }}
      </v-icon>
    </v-btn>

    <v-container
      fluid
      style="max-width: 1200px"
      :class="$vuetify.breakpoint.md ? 'mt-8': 'mt-2'"
    >
      <v-col class="mb-4">
        <v-row>
          <div
            class="d-flex justify-center align-center"
            style="flex: 1"
          >
            <v-avatar
              class="d-flex grey lighten-2"
              :size="avatarSize"
            >
              <v-img
                data-cy="profile-image"
                data-dd-privacy="hidden"
                :src="profileImage"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      size="24"
                      indeterminate
                      color="grey lighten-1"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </div>
          <v-col style="min-width: 75%">
            <v-row class="d-flex justify-space-between mt-0 mt-sm-4">
              <div
                v-if="name"
                class="d-flex text-h6 text-sm-h5 font-weight-bold textBlack--text"
                style="word-break: break-word; flex: 1"
                data-cy="person-viewer-name"
                data-dd-privacy="mask"
              >
                {{ name }}
              </div>
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="heading"
              />
              <square-button
                data-cy="person-viewer-settings"
                :icon-name="icons.dotsHorizontal"
                icon-color="neutral"
                color="transparentBackground"
                outlined
              >
                <v-list
                  dense
                  elevation="0"
                >
                  <v-list-item
                    data-cy="person-viewer-report-false-negative"
                    :disabled="canReportFalseNegative"
                    @click="openReportFalseNegativeDialog"
                  >
                    {{ $t('deconve.reportFalseNegative') }}
                  </v-list-item>
                  <v-list-item
                    v-if="expireAt"
                    data-cy="person-viewer-restore-from-trash"
                    :disabled="!$can('update', 'com.deconve.faceid.person')"
                    @click="restorePersonFromTrash"
                  >
                    {{ $t('deconve.restoreFromTrash') }}
                  </v-list-item>
                  <v-list-item
                    v-else
                    data-cy="person-viewer-move-to-trash"
                    :disabled="!$can('update', 'com.deconve.faceid.person')"
                    @click="movePersonToTrash"
                  >
                    {{ $t('deconve.moveToTrash') }}
                  </v-list-item>
                  <v-list-item
                    v-if="isOnDeconveHubWorkspace"
                    @click="goToSuperProfilePage"
                  >
                    {{ $t('deconve.seeSuperProfile') }}
                  </v-list-item>
                  <v-list-item
                    data-cy="delete-person"
                    :disabled="!$can('delete', 'com.deconve.faceid.person')"
                    @click="deletePersonDialog"
                  >
                    {{ $t('deconve.delete') }}
                  </v-list-item>
                  <v-list-item
                    v-if="isAllowedToDisable"
                    @click="openDisablePersonDialog"
                  >
                    {{ $t('deconve.disable') }}
                  </v-list-item>
                  <v-list-item
                    v-else-if="isOnDeconveHubWorkspace"
                    @click="activatePersonProfile"
                  >
                    {{ $t('deconve.activate') }}
                  </v-list-item>
                </v-list>
              </square-button>
            </v-row>
            <v-row>
              <div
                v-if="createdAt"
                class="
                    d-flex
                    align-center
                    neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2
                    mr-2
                  "
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  size="20"
                >
                  {{ icons.calendarRange }}
                </v-icon>
                {{ createdAt | moment('DD/MM/YYYY') }}
              </div>
              <v-skeleton-loader
                v-else
                class="mr-2"
                type="text"
                style="flex: 1"
                max-width="100px"
              />
              <div
                v-if="updatedAt"
                class="
                    d-flex
                    align-center
                    neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2
                    mr-4
                  "
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  size="20"
                >
                  {{ icons.pencilOutline }}
                </v-icon>
                {{ updatedAt | moment('DD/MM/YYYY') }}
              </div>
              <v-skeleton-loader
                v-else
                type="text"
                style="flex: 1"
                max-width="100px"
              />
            </v-row>
            <v-row v-if="isOnDeconveHubWorkspace">
              <div
                v-if="personWorkspaceName"
                class="
                    d-flex
                    align-center
                    neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2
                    mr-2
                    my-2
                  "
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  size="20"
                >
                  {{ icons.workspaceIcon }}
                </v-icon>
                {{ personWorkspaceName }}
              </div>
              <v-skeleton-loader
                v-else
                class="mr-2"
                type="text"
                style="flex: 1"
                max-width="100px"
              />
            </v-row>
            <v-row>
              <div class="d-flex flex-wrap">
                <tag
                  v-for="personTag in tags"
                  :key="personTag.id"
                  class="pa-1"
                  :tag-id="personTag.id"
                />
              </div>
            </v-row>
            <v-row
              data-cy="person-viewer-about"
              class="mt-6 neutral--text body-2 text-sm-body-1"
              style="word-break: break-word"
            >
              <div v-if="person">
                {{ about || $t('deconve.person.noDescription') }}
              </div>
              <v-skeleton-loader
                v-else
                class="mt-6"
                type="paragraph"
                style="flex: 1"
              />
            </v-row>
            <v-row
              v-if="vehicleLicensePlate"
              class="mt-6 neutral--text body-2 text-sm-body-1"
              style="word-break: break-word"
            >
              <span>
                <strong>{{ $t('deconve.vehicleLicensePlate') }}:</strong>
                {{ vehicleLicensePlate }}
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-tabs
            v-model="tab"
            grow
            centered
            style="border-bottom: 1px solid"
            :style="{ borderBottomColor: $vuetify.theme.themes.light.border }"
          >
            <v-tabs-slider color="primary" />

            <!-- tab for images -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                size="24"
                class="mr-2"
              >
                {{ icons.imageMultipleOutline }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.images') }}
              </span>
            </v-tab>

            <!-- tab for occurrence reports -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.fileMultipleOutline }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.occurrenceReports') }}
              </span>
            </v-tab>

            <!-- tab for videos -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.videoFilesOutline }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.videos') }}
              </span>
            </v-tab>

            <!-- tab for units -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.units }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.units') }}
              </span>
            </v-tab>

            <!-- tab for related profiles -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.personMultiple }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.relatedProfiles') }}
              </span>
            </v-tab>

            <!-- tab for notifications -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.bellOutline }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.notifications') }}
              </span>
            </v-tab>

            <!-- tab for history -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.history }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.history') }}
              </span>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            style="width: 100%"
          >
            <v-tab-item style="width: 100%">
              <profile-images :is-loading="isLoading" />
            </v-tab-item>
            <!-- where the occurrence files will be displayed -->
            <v-tab-item>
              <profile-files
                :person-id="personId"
                :is-loading="isLoading"
              />
            </v-tab-item>
            <v-tab-item>
              <profile-videos
                :person-id="personId"
                :is-loading="isLoading"
              />
            </v-tab-item>
            <v-tab-item>
              <profile-units
                :profile-id="personId"
                :is-loading="isLoading"
                :is-person-request="true"
              />
            </v-tab-item>
            <v-tab-item>
              <profile-related-people :related-profiles="relatedProfiles" />
            </v-tab-item>
            <v-tab-item>
              <person-notifications
                :person-id="personId"
                :person-name="name"
              />
            </v-tab-item>
            <v-tab-item class="pt-5">
              <history-item
                v-for="(item, i) in personHistory"
                :key="i"
                :action="item.action"
                :created-by="item.created_by"
                :created-at="item.created_at"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
      <tags-manager-dialog ref="tagDialog" />
      <confirmation-dialog ref="confirm" />
      <report-false-negative-dialog ref="reportFalseNegativeDialog" />
      <disable-person-dialog ref="disablePersonDialog" />
      <div v-if="enableAlerts && !isLoading">
        <v-snackbar
          v-model="enableAlerts"
          data-cy="snackbar"
          :color="getAlertColor"
        >
          {{ translatedErrorMessage || alertMessage }}

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="enableAlerts = false"
            >
              <v-icon color="white">
                {{ icons.close }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
    <v-btn
      fab
      color="primary"
      fixed
      bottom
      right
      data-cy="button-edit"
      :disabled="!$can('update', 'com.deconve.faceid.person') || !isEditEnabled"
      @click="$emit('onEditButtonClicked')"
    >
      <v-icon>{{ icons.pencilOutline }}</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import DisablePersonDialog from '@/components/DisablePersonDialog.vue';
import SquareButton from '@/components/SquareButton.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import ReportFalseNegativeDialog from '@/components/ReportFalseNegativeDialog.vue';
import Tag from '@/components/Tag.vue';
import HistoryItem from '@/components/HistoryItem.vue';
import { downloadImage } from '@/api';

import {
  mdiClose, mdiAlertCircleOutline, mdiChevronLeft,
  mdiDotsHorizontal, mdiCalendarRange,
  mdiPencilOutline, mdiImageMultipleOutline,
  mdiFileMultipleOutline, mdiPlayBoxMultipleOutline,
  mdiTagMultipleOutline, mdiBellOutline, mdiHistory,
  mdiMessageDraw, mdiStoreOutline,
  mdiAccountMultipleOutline, mdiMenu, mdiFolderOutline,
} from '@mdi/js';
import ProfileUnits from '@/components/ProfileUnits.vue';
import ProfileImages from './ProfileImages.vue';
import PersonNotifications from './PersonNotifications.vue';
import ProfileFiles from './ProfileFiles.vue';
import ProfileVideos from './ProfileVideos.vue';
import ProfileRelatedPeople from './ProfileRelatedPeople.vue';

export default {
  name: 'PersonViewer',
  components: {
    ConfirmationDialog,
    DisablePersonDialog,
    TagsManagerDialog,
    SquareButton,
    Tag,
    ProfileImages,
    PersonNotifications,
    HistoryItem,
    ProfileFiles,
    ProfileVideos,
    ProfileUnits,
    ProfileRelatedPeople,
    ReportFalseNegativeDialog,
  },
  props: {
    personId: { type: String, required: true },
  },
  data() {
    return {
      tab: 0,
      alertMessage: '',
      alertColor: '',
      showImageInfos: false,
      showPersonFacesInfo: false,
      // To be able to change replace the image in the VueCropper, we are re-renders the component.
      // Without this, when changing the profile image, VueCropper is not updating its layout.
      enableAlerts: false,
      person: null,
      profileImage: '',
      isToShowButtonOptions: false,
      personWorkspace: undefined,
      icons: {
        close: mdiClose,
        alertCircleOutline: mdiAlertCircleOutline,
        chevronLeft: mdiChevronLeft,
        dotsHorizontal: mdiDotsHorizontal,
        calendarRange: mdiCalendarRange,
        pencilOutline: mdiPencilOutline,
        imageMultipleOutline: mdiImageMultipleOutline,
        fileMultipleOutline: mdiFileMultipleOutline,
        videoFilesOutline: mdiPlayBoxMultipleOutline,
        tagMultipleOutline: mdiTagMultipleOutline,
        bellOutline: mdiBellOutline,
        history: mdiHistory,
        messageDraw: mdiMessageDraw,
        units: mdiStoreOutline,
        personMultiple: mdiAccountMultipleOutline,
        menu: mdiMenu,
        workspaceIcon: mdiFolderOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      getNotificationPerson: 'faceid/getNotificationPerson',
      getPerson: 'faceid/getPerson',
      personImages: 'faceid/personImages',
      personImagesNumber: 'faceid/personImagesNumber',
      isLoading: 'faceid/isLoadingPerson',
      errorMessage: 'faceid/addPersonError',
      myWorkspace: 'workspace/myWorkspace',
    }),
    personWorkspaceName() {
      if (this.personWorkspace) {
        return this.personWorkspace?.name;
      }

      return '';
    },
    buttonIconSelection() {
      return this.isToShowButtonOptions ? this.icons.close : this.icons.menu;
    },
    personRef() {
      return this.getPerson(this.personId);
    },
    name() {
      return this.person?.name || '';
    },
    createdAt() {
      return this.person?.created_at || '';
    },
    updatedAt() {
      return this.person?.updated_at || '';
    },
    expireAt() {
      return this.person?.expire_at || '';
    },
    about() {
      return this.person?.about || '';
    },
    tags() {
      return this.person?.tags || [];
    },
    relatedProfiles() {
      return this.person?.related_profiles || [];
    },
    superProfileId() {
      return this.person?.super_profile.id;
    },
    faces() {
      const selectedFaces = [];

      this.personImages.forEach((img, i) => {
        if (img.info?.faces.length > 0) {
          selectedFaces.push({
            image: img.originalImage || img.mediumImage || img.thumbnailImage,
            info: img.info,
            originalHeight: img.originalHeight,
            originalWidth: img.originalWidth,
            index: i,
          });
        }
      });

      return selectedFaces;
    },
    getAlertColor() {
      if (this.alertColor) {
        return this.alertColor;
      }

      return this.errorMessage ? 'warn' : 'primary';
    },
    isEditEnabled() {
      return !this.expireAt;
    },
    translatedErrorMessage() {
      if (this.errorMessage) {
        const { id: errorId } = this.errorMessage;

        if (errorId === 'duplicate_person_name') {
          return this.$t('deconve.person.alerts.duplicatePersonName');
        }

        return this.$t('deconve.error.unknown');
      }

      return null;
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '150px';
        case 'sm': return '190px';
        case 'md': return '200px';
        case 'lg': return '250px';
        case 'xl': return '250px';
        default: return '150px';
      }
    },
    vehicleLicensePlate() {
      return this.person?.vehicle_license_plate || '';
    },
    personHistory() {
      const person = this.getNotificationPerson(this.personId);

      if (person) {
        this.sortHistoryInDescendingOrder(person);
        return person;
      }

      return [];
    },
    currentWorkspaceId() {
      const workspaceId = this.myWorkspace.id;

      return workspaceId;
    },
    personStatus() {
      return this.person?.sharing?.current_review?.status;
    },
    personStatusTranslated() {
      switch (this.personStatus) {
        case 'approved':
          return this.$t('deconve.approved');
        case 'rejected':
          return this.$t('deconve.rejected');
        case 'waiting':
          return this.$t('deconve.awaitingReview');
        default:
          return '';
      }
    },
    personStatusClass() {
      switch (this.personStatus) {
        case 'approved':
          return 'positive--text';
        case 'rejected':
          return 'negative--text';
        case 'waiting':
          return 'warning--text';
        default:
          return '';
      }
    },
    canReportFalseNegative() {
      return !this.$can('update', 'com.deconve.faceid.person') && !this.$can('update', 'com.deconve.faceid.notification');
    },
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
    isPersonEnabled() {
      return this.person?.enabled;
    },
    isAllowedToDisable() {
      return this.isOnDeconveHubWorkspace && this.isPersonEnabled;
    },
    personDisableReasonCode() {
      return this.person?.disable_reason_code;
    },
    reasonForDeactivation() {
      switch (this.personDisableReasonCode) {
        case 'lgpd_violation':
          return this.$t('deconve.person.disabled.byViolationLgpd');
        case 'low_quality_image':
          return this.$t('deconve.person.disabled.byLowQualityImage');
        case 'excessive_false_alerts':
          return this.$t('deconve.person.disabled.byExcessiveFalseAlerts');
        default:
          return '';
      }
    },
  },
  watch: {
    showImageInfos(isActive) {
      setTimeout(() => {
        if (isActive) {
          const imageInfo = this.$refs.imageInfos;

          if (imageInfo) {
            imageInfo.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100);
    },
    personRef() {
      this.person = this.personRef;
    },
    updatedAt() {
      // Only reload the images if ther person was updated
      this.resetPersonImages();
      this.getImages();

      this.resetPersonFiles();
      this.getFiles();

      this.resetPersonVideos();
      this.fetchVideos();
    },
  },
  created() {
    this.fetchPerson(this.personId).then((data) => {
      this.person = data;

      if (this.isOnDeconveHubWorkspace) {
        this.fetchWorkspace(data.workspace.id).then((workspaceInfo) => {
          this.personWorkspace = workspaceInfo;
        });

        this.fetchTags(data.workspace.id);
      }
    });
  },
  beforeDestroy() {
    if (this.isOnDeconveHubWorkspace) this.fetchTags();
  },
  methods: {
    ...mapActions({
      changePersonActivation: 'faceid/changePersonActivation',
      fetchPerson: 'faceid/fetchPerson',
      fetchPersonImages: 'faceid/fetchPersonImages',
      fetchPersonFiles: 'faceid/fetchPersonFiles',
      fetchPersonVideos: 'faceid/fetchPersonVideos',
      fetchTags: 'tags/fetchTags',
      editPerson: 'faceid/editPerson',
      deletePerson: 'faceid/deletePerson',
      resetPersonImages: 'faceid/resetPersonImages',
      resetPersonFiles: 'faceid/resetPersonFiles',
      resetPersonVideos: 'faceid/resetPersonVideos',
      fetchWorkspace: 'workspace/fetchWorkspace',
    }),
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    movePersonToTrash() {
      const form = new FormData();

      form.append('move_to_trash', true);
      this.editPerson({ personId: this.personId, payload: form });
    },
    restorePersonFromTrash() {
      const form = new FormData();

      form.append('restore_from_trash', true);
      this.editPerson({ personId: this.personId, payload: form });
    },
    sortHistoryInDescendingOrder(person) {
      person.sort((a, b) => -a.created_at.localeCompare(b.created_at));
    },
    deletePersonDialog() {
      this.$refs.confirm.open(
        this.$t('deconve.person.deleteDialog.title'),
        this.$t('deconve.person.deleteDialog.message'),
      ).then((confirm) => {
        if (confirm) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.deletePerson(this.personId).then(() => this.goBack()).catch(() => { });
        }
      });
    },
    getImages() {
      if (this.person) {
        const { profile_image_url: profileImageUrl } = this.person;

        downloadImage(profileImageUrl).then((image) => {
          this.profileImage = image;
        });

        this.fetchPersonImages({ images: this.person.images, personId: this.personId });
      }
    },
    getFiles() {
      if (this.person) {
        this.fetchPersonFiles({ files: this.person.files, personId: this.personId });
      }
    },
    fetchVideos() {
      if (this.person) {
        this.fetchPersonVideos({ videos: this.person.videos, personId: this.personId });
      }
    },
    openReportFalseNegativeDialog() {
      this.$refs.reportFalseNegativeDialog.open({
        personId: this.personId,
      });
    },
    goToSuperProfilePage() {
      this.$router.push({ name: 'faceid-super-profile', params: { superProfileId: this.superProfileId } });
    },
    openDisablePersonDialog() {
      this.$refs.disablePersonDialog.open({
        personId: this.personId,
      });
    },
    activatePersonProfile() {
      this.changePersonActivation({ personId: this.personId });
    },
  },
};
</script>

<style scoped>
#personContainer .v-speed-dial {
  position: absolute;
}

#personContainer .v-btn--floating {
  position: relative;
}
</style>
