<template>
  <v-container
    class="pa-2"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>{{ icons.chevronLeft }}</v-icon>
    </v-btn>

    <v-form
      ref="form"
      v-model="valid"
      :class="$vuetify.breakpoint.mobile ? 'mt-8': 'mt-0'"
    >
      <v-container
        fluid
        style="max-width: 1200px"
      >
        <v-col
          dense
        >
          <v-row
            v-if="isRegisteringPersonInAnotherWorkspace"
          >
            <alert
              type="warning"
              color="warning"
              elevation="2"
              class="mt-2"
            >
              <div>
                {{ selectAlertTitle }}
              </div>
            </alert>
          </v-row>
          <v-row
            class="align-center mb-1"
          >
            <v-icon
              class="mr-2"
              color="textBlack"
              :size="$vuetify.breakpoint.mobile? 24 : 28"
            >
              {{ icons.account }}
            </v-icon>
            <div class="subtitle-1 font-weight-bold textBlack--text">
              {{ $t('deconve.details') }}
            </div>
          </v-row>
          <v-row>
            <v-text-field
              v-model="name"
              data-cy="person-field-name"
              :counter="64"
              :rules="nameRules"
              :label="$t('deconve.name')"
              class="body-2 text-sm-body-1"
              required
              outlined
              @click="resetAlert"
            />
          </v-row>
          <v-row>
            <v-textarea
              v-model="about"
              data-cy="person-field-about"
              data-dd-privacy="mask-user-input"
              :counter="maxAboutTextLength"
              :rules="aboutRules"
              :label="$t('deconve.about')"
              class="body-2 text-sm-body-1"
              outlined
              @click="resetAlert"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="vehicleLicensePlate"
              data-cy="person-field-vehicleLicensePlate"
              data-dd-privacy="mask-user-input"
              counter="15"
              :label="$t('deconve.vehicleLicensePlate')"
              class="body-2 text-sm-body-1"
              required
              outlined
              :rules="vehicleLicensePlateRules"
              @click="resetAlert"
            />
          </v-row>

          <!-- tags -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile? 22 : 26"
              >
                {{ icons.tagMultiple }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.tags') }}
              </div>
            </div>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openTagModal"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.tagPlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.tag.addTags') }}
              </div>
            </v-btn>
          </v-row>
          <v-row
            v-if="tags.length > 0"
            class="mb-4"
          >
            <div class="d-flex flex-wrap">
              <tag
                v-for="tag in tags"
                :key="tag.id"
                class="pa-1"
                :tag-id="tag.id"
              />
            </div>
          </v-row>

          <!-- unit -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 22 : 26"
              >
                {{ icons.storeMarker }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.unit') }}
              </div>
            </div>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openUnitModal"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.storePlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.addUnit') }}
              </div>
            </v-btn>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <v-alert
                v-if="isToShowUnitNotFoundMessage"
                dense
                type="error"
                color="warn"
              >
                {{ $t('deconve.unitNotFound', [unitId]) }}
              </v-alert>

              <div
                v-if="unitName"
                class="d-flex flex-wrap"
              >
                <v-chip
                  label
                  outlined
                >
                  {{ unitName }}
                </v-chip>
              </div>
              <div
                v-if="!unitName"
                class="d-flex flex-wrap"
              >
                <v-checkbox
                  v-model="isFromUnknownUnit"
                  class="pa-0 ma-0"
                  :label="$t('deconve.unknownUnit')"
                />
              </div>
            </v-col>
          </v-row>

          <!-- monitoring -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 22 : 26"
              >
                {{ icons.videoMonitoring }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.monitoringArea') }}
              </div>
            </div>
            <div>
              <square-button
                icon-name="mdi-help-circle-outline"
                :icon-color="showMonitoringInfo? 'white' : 'neutral'"
                :color="showMonitoringInfo? 'info' : 'transparentBackground'"
                menu-disabled
                :tooltip-message="$t('deconve.info')"
                :outlined="!showMonitoringInfo"
                fab
                @clicked="showMonitoringInfo= !showMonitoringInfo"
              />
            </div>
          </v-row>

          <v-row v-if="showMonitoringInfo">
            <alert
              type="info"
              color="info"
            >
              <div>
                {{ $t('deconve.person.alerts.infos.monitoringInfo') }}
              </div>
            </alert>
          </v-row>

          <v-row>
            <v-radio-group
              v-model="isMonitoringAreaDelimitedByTags"
              class="pt-0 mt-0"
            >
              <v-radio
                :label="$t('deconve.monitoringInNearbyUnits')"
                :value="false"
              />
              <v-radio
                :label="$t('deconve.monitoringByTags')"
                :value="true"
              />
            </v-radio-group>
          </v-row>

          <!-- related profiles -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 22 : 26"
              >
                {{ icons.personMultiple }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.relatedProfiles') }}
              </div>
            </div>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openRelatedProfilesModal"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.personMultiple }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.selectProfiles') }}
              </div>
            </v-btn>
          </v-row>

          <v-row
            v-if="relatedProfiles.length > 0"
            class="mb-4"
          >
            <div class="d-flex flex-wrap">
              <person-preview-line
                v-for="(id) in relatedProfiles"
                :key="id"
                data-dd-privacy="hidden"
                :show-only-image="true"
                :person-id="id"
              />
            </div>
          </v-row>

          <!-- occurrence reports -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 22 : 26"
              >
                {{ icons.fileDocument }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.occurrenceReports') }}
              </div>
            </div>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openFileSelection('file-input')"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.fileDocumentPlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.addOccurrenceReports') }}
              </div>
            </v-btn>
            <input
              id="file-input"
              accept="application/pdf"
              type="file"
              multiple
              required
              style="display: none;"
              @change="loadFiles($event.target.files)"
            >
          </v-row>
          <v-row
            v-if="files.length > 0"
            class="mb-4"
          >
            <div class="d-flex flex-wrap">
              <file-item
                v-for="file in files"
                :key="file.name"
                is-edit-mode
                :name="file.name"
                class="pa-1"
                @delete="handleDeleteFile(file.name)"
              />
            </div>
          </v-row>

          <!-- videos -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 22 : 26"
              >
                {{ icons.videoFile }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.videos') }}
              </div>
            </div>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openFileSelection('video-input')"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.videoFilePlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.addVideos') }}
              </div>
            </v-btn>
            <input
              id="video-input"
              accept="video/*"
              type="file"
              multiple
              required
              style="display: none;"
              @change="loadVideoFiles($event.target.files)"
            >
          </v-row>
          <v-row
            v-if="videos.length > 0"
            class="mb-4"
          >
            <div class="d-flex flex-wrap">
              <file-item
                v-for="file in videos"
                :key="file.name"
                is-edit-mode
                :name="file.name"
                class="pa-1"
                @delete="handleDeleteVideo(file.name)"
              />
            </div>
          </v-row>
        </v-col>

        <!-- faces -->
        <v-col dense>
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div class="d-flex align-center">
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 16 : 20"
              >
                {{ icons.faceRecognition }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.person.facesOfThisPerson') }}
              </div>
            </div>
            <v-switch
              v-if="isEditMode"
              v-model="enableSimilarPersonSearch"
              :label="$t('deconve.enableSimilarPersonSearch')"
            />
          </v-row>
          <face-list
            :faces="faces"
            data-dd-privacy="hidden"
            :enable-similar-person-search="enableSimilarPersonSearch"
            :is-loading="isEditMode && personImages.length === 0"
            :is-edit-mode="isEditMode"
            @onFaceSelected="setCropImage"
          />
          <v-row
            align="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 20 : 24"
              >
                {{ icons.images }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ personImages.length }} {{ $t('deconve.images') }}
              </div>
            </div>
            <square-button
              icon-name="mdi-information-outline"
              :icon-color="showImageInfos? 'white' : 'neutral'"
              :color="showImageInfos? 'info' : 'transparentBackground'"
              menu-disabled
              :tooltip-message="$t('deconve.info')"
              :outlined="!showImageInfos"
              fab
              @clicked="showImageInfos= !showImageInfos"
            />
          </v-row>
          <v-row v-if="showImageCropper">
            <image-cropper-editor
              :key="imageCropperKey"
              data-dd-privacy="hidden"
              :images="personImages"
              :index="cropIndex"
              :is-edit-mode="true"
              @close="onCloseCropper"
              @selectFace="handleSelectFace"
              @detectFaces="handleDetectFaces"
              @deleteFaces="handleDeleteFaces"
              @remove="handleRemoveImage"
              @changed="updateCropIndex"
            />
          </v-row>
          <v-row
            v-else
            justify="center"
          >
            <v-avatar
              v-if="profileImage"
              class="profile mb-6"
              color="grey"
              :size="avatarSize"
            >
              <v-img
                data-cy="profile-image-cropper"
                data-dd-privacy="hidden"
                :src="profileImage"
              />
            </v-avatar>
          </v-row>
          <image-list
            data-dd-privacy="hidden"
            :images="personImages"
            :is-edit-mode="true"
            :is-loading="personImages.length === 0"
            :score-status="true"
            @set-crop-image="setCropImage"
            @load-images="loadImages"
          />
          <v-row ref="imageInfos">
            <alert
              v-if="showImageInfos"
              type="info"
              color="info"
              class="mt-6"
            >
              <div>
                {{ $t('deconve.person.alerts.infos.inputFiles') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <alert
              v-if="showImageInfos"
              type="info"
              color="info"
            >
              <div>
                {{ $t('deconve.person.alerts.infos.qualityMeasurement') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <alert
              v-if="showImageInfos"
              type="info"
              color="info"
            >
              <div class="mb-2">
                {{ $t('deconve.person.alerts.infos.selectSamePerson') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <alert
              v-if="showImageInfos"
              type="info"
              color="info"
            >
              <strong class="my-2">
                {{ $t('deconve.person.alerts.infos.resolution.title') }}
              </strong>
              <div class="my-2">
                {{ $t('deconve.person.alerts.infos.resolution.notAccepted') }}
              </div>
              <div class="mb-2">
                {{ $t('deconve.person.alerts.infos.resolution.low') }}
              </div>
              <div class="mb-2">
                {{ $t('deconve.person.alerts.infos.resolution.good') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <alert
              v-if="showImageInfos"
              type="info"
              color="info"
            >
              <div class="mb-2">
                {{ $t('deconve.person.alerts.infos.resolution.measurement') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <alert
              v-if="showImageInfos"
              type="info"
              color="info"
            >
              <strong class="my-2">
                {{ $t('deconve.person.image.quality.title') }}
              </strong>
              <div class="mt-2">
                <image-examples />
              </div>
            </alert>
          </v-row>
        </v-col>
        <v-col class="mt-4">
          <v-row class="d-flex justify-end">
            <rectangle-button
              v-if="!personId"
              data-cy="person-button-reset"
              color="info"
              outlined
              @clicked="reset"
            >
              {{ $t('deconve.reset') }}
            </rectangle-button>
            <rectangle-button
              v-if="personId"
              data-cy="person-button-cancel"
              color="warn"
              outlined
              class="ml-2"
              @clicked="cancelEdit"
            >
              {{ $t('deconve.cancel') }}
            </rectangle-button>
            <rectangle-button
              :is-loading="isSendingProfile"
              data-cy="person-button-submit"
              color="primary"
              class="ml-2"
              :disabled="(!imageRules || !valid)"
              @clicked="validationToSubmit"
            >
              {{ $t('deconve.submit') }}
            </rectangle-button>
          </v-row>
        </v-col>
        <tags-manager-dialog
          ref="tagDialog"
          :workspace-id="selectedWorkspaceId"
          :is-on-deconve-hub="isOnDeconveHubWorkspace"
        />
        <unit-selection-dialog
          ref="unitDialog"
          :workspace-id="selectedWorkspaceId"
          :is-on-deconve-hub="isOnDeconveHubWorkspace"
        />
        <related-profiles-dialog
          ref="profilesDialog"
          :person-id="personId"
          :workspace-id="selectedWorkspaceId"
          :is-on-deconve-hub="isOnDeconveHubWorkspace"
        />
        <confirmation-dialog ref="confirm" />
        <workspaces-dialog
          ref="workspacesDialog"
        />
        <div v-if="alert && !isLoading && alertMessageText">
          <v-snackbar
            v-model="alert"
            data-cy="snackbar"
            :color="getAlertColor"
          >
            {{ alertMessageText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="resetAlert"
              >
                <v-icon color="white">
                  {{ icons.close }}
                </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import ImageList from '@/components/ImageList.vue';
import ImageCropperEditor from '@/components/ImageCropperEditor.vue';
import Alert from '@/components/Alert.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import SquareButton from '@/components/SquareButton.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import UnitSelectionDialog from '@/components/UnitSelectionDialog.vue';
import RelatedProfilesDialog from '@/components/RelatedProfilesDialog.vue';
import ImageExamples from '@/components/ImageExamples.vue';
import Tag from '@/components/Tag.vue';
import FaceList from '@/components/FaceList.vue';
import FileItem from '@/components/FileItem.vue';
import PersonPreviewLine from '@/components/PersonPreviewLine.vue';
import WorkspacesDialog from '@/components/WorkspacesDialog.vue';
import {
  mdiChevronLeft, mdiAccountOutline,
  mdiTagMultipleOutline, mdiTagPlusOutline,
  mdiStoreMarkerOutline, mdiStorePlusOutline,
  mdiFileDocumentOutline, mdiFileDocumentPlusOutline,
  mdiHelpCircleOutline, mdiVideoMarkerOutline, mdiFaceRecognition,
  mdiImageMultipleOutline, mdiClose, mdiAccountMultipleOutline,
  mdiPlayBoxMultipleOutline, mdiMovieOpenPlusOutline,
} from '@mdi/js';
import imageQualityScore, { LOW_QUALITY_IMAGE, REGULAR_QUALITY_IMAGE } from '../../../utils/imageQualityScore';
import imageResolutionScore, { NOT_ACCEPTED_RESOLUTION_IMAGE, LOW_RESOLUTION_IMAGE } from '../../../utils/imageResolutionScore';
import { dataUrlToBlob } from '../../../utils/data';
import SCOPES from '../../../utils/scopes';

export default {
  name: 'PersonRegister',
  components: {
    ConfirmationDialog,
    TagsManagerDialog,
    ImageList,
    FileItem,
    ImageCropperEditor,
    Alert,
    RectangleButton,
    SquareButton,
    ImageExamples,
    Tag,
    FaceList,
    UnitSelectionDialog,
    RelatedProfilesDialog,
    PersonPreviewLine,
    WorkspacesDialog,
  },
  props: {
    personId: { type: String, default: '' },
    modeRegister: { type: Boolean, default: false },
  },
  data() {
    return {
      showImageCropper: false,
      enableSimilarPersonSearch: true,
      valid: true,
      // TODO(spidteam): we will decrease this value after we develop the feature to link person
      // profiles
      imagesNumberLimit: 20,
      name: '',
      about: '',
      vehicleLicensePlate: '',
      createdAt: '',
      updatedAt: '',
      alertMessage: '',
      alertColor: '',
      cropIndex: 0,
      maxAboutTextLength: 356,
      showImageInfos: false,
      showMonitoringInfo: false,
      showPersonFacesInfo: false,
      // To be able to change replace the image in the VueCropper, we are re-renders the component.
      // Without this, when changing the profile image, VueCropper is not updating its layout.
      imageCropperKey: 1,
      alert: false,
      wasImageEdited: false,
      tags: [],
      unit: null,
      relatedProfiles: [],
      isCurrentUnitIdValid: true,
      imageFiles: [],
      files: [],
      filesToBeDeleted: [],
      videos: [],
      videosToBeDeleted: [],
      removingPerson: false,
      isSendingProfile: false,
      personData: null,
      isEditMode: false,
      isFromUnknownUnit: false,
      workspaceSettings: null,
      selectedWorkspaceId: undefined,
      isMonitoringAreaDelimitedByTags: false,
      icons: {
        chevronLeft: mdiChevronLeft,
        account: mdiAccountOutline,
        tagMultiple: mdiTagMultipleOutline,
        tagPlus: mdiTagPlusOutline,
        storeMarker: mdiStoreMarkerOutline,
        storePlus: mdiStorePlusOutline,
        fileDocument: mdiFileDocumentOutline,
        fileDocumentPlus: mdiFileDocumentPlusOutline,
        videoMonitoring: mdiVideoMarkerOutline,
        videoFile: mdiPlayBoxMultipleOutline,
        videoFilePlus: mdiMovieOpenPlusOutline,
        faceRecognition: mdiFaceRecognition,
        images: mdiImageMultipleOutline,
        close: mdiClose,
        personMultiple: mdiAccountMultipleOutline,
        help: mdiHelpCircleOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPerson: 'faceid/getPerson',
      getUnit: 'units/getUnit',
      units: 'units/getUnits',
      personImages: 'faceid/personImages',
      profileImage: 'faceid/getPersonProfileImage',
      personImagesNumber: 'faceid/personImagesNumber',
      personFiles: 'faceid/personFiles',
      personVideos: 'faceid/personVideos',
      isLoading: 'faceid/isLoadingPerson',
      errorMessage: 'faceid/addPersonError',
      personImagesId: 'faceid/personImagesId',
      myWorkspace: 'workspace/myWorkspace',
    }),
    isRegisteringPersonInAnotherWorkspace() {
      return Boolean(this.selectedWorkspaceId);
    },
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
    getWorkspaceName() {
      return this.workspaceSettings?.name || this.selectedWorkspaceId;
    },
    selectAlertTitle() {
      const textType = this.isEditMode ? 'selectedWorkspaceEdit' : 'selectedWorkspace';

      return this.$tc(`deconve.person.alerts.infos.${textType}`, this.getWorkspaceName);
    },
    personDataRef() {
      return this.getPerson(this.id);
    },
    faces() {
      const selectedFaces = [];

      this.personImages.forEach((img, i) => {
        if (img.info?.faces.length > 0) {
          selectedFaces.push({
            image: img.originalImage || img.mediumImage || img.thumbnailImage,
            name: img.originalName || img.mediumName,
            info: img.info,
            originalHeight: img.originalHeight,
            originalWidth: img.originalWidth,
            index: i,
          });
        }
      });

      return selectedFaces;
    },
    getAlertColor() {
      if (this.alertColor) {
        return this.alertColor;
      }

      return this.errorMessage ? 'warn' : 'primary';
    },
    translatedErrorMessage() {
      if (this.errorMessage) {
        const { id: errorId } = this.errorMessage;

        if (errorId === 'duplicate_person_name') {
          return this.$t('deconve.person.alerts.duplicatePersonName');
        }

        return this.$t('deconve.error.unknown');
      }

      return null;
    },
    alertMessageText() {
      return this.translatedErrorMessage || this.alertMessage;
    },
    imageRules() {
      return this.personImages.length > 0;
    },
    nameRules() {
      return [
        (v) => !!v || this.$t('deconve.inputRules.name.required'),
        (v) => (v && v.length <= 64) || this.$t('deconve.inputRules.name.limit'),
      ];
    },
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    vehicleLicensePlateRules() {
      const noSpecialCharacters = /\W/;

      return [
        (v) => (!v || (v && v.length <= 15)) || this.$t('deconve.inputRules.vehicleLicensePlate.limit'),
        (v) => (!v || !noSpecialCharacters.test(v)) || this.$t('deconve.inputRules.noSpecialCharacters'),
      ];
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '150px';
        case 'sm': return '190px';
        case 'md': return '200px';
        case 'lg': return '250px';
        case 'xl': return '250px';
        default: return '150px';
      }
    },
    unitId() {
      return this.personData?.unit?.id;
    },
    isToShowUnitNotFoundMessage() {
      return !this.isCurrentUnitIdValid && !this.unitName && !this.isFromUnknownUnit;
    },
    unitName() {
      return this.unit?.name;
    },
  },
  watch: {
    showImageInfos(isActive) {
      setTimeout(() => {
        if (isActive) {
          const imageInfo = this.$refs.imageInfos;

          if (imageInfo) {
            imageInfo.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100);
    },
    personDataRef() {
      this.personData = this.personDataRef;
    },
    personData(data) {
      if (this.isEditMode) {
        this.initEdit(data);
        this.getDefaultPersonUnit();
        this.getDefaultPersonTags();
        this.getDefaultRelatedProfiles();
      } else {
        this.resetAllPersonFiles();
      }
    },
    personImages() {
      // Change the person profile image when the profile image is deleted
      if (this.personImages.length > 0 && this.profileImage === '') {
        this.addPersonProfileImage(this.personImages[0].originalImage || '');
      }
    },
    units() {
      if (this.isEditMode) {
        this.getDefaultPersonUnit();
      }
    },
    unit() {
      if (this.unit) {
        this.isFromUnknownUnit = false;
      } else {
        this.isFromUnknownUnit = true;
      }
    },
    isFromUnknownUnit() {
      if (this.isFromUnknownUnit) {
        this.isMonitoringAreaDelimitedByTags = true;
      }
    },
  },
  created() {
    this.resetAllPersonFiles();

    if (this.personId) {
      this.isEditMode = true;
      this.enableSimilarPersonSearch = false;
      this.fetchPerson(this.personId).then((personData) => {
        this.personData = personData;

        if (this.isOnDeconveHubWorkspace) {
          this.selectedWorkspaceId = personData.workspace.id;
          this.setWorkspaceSettings(this.selectedWorkspaceId);
        }
      });
    } else {
      this.isEditMode = false;
      this.workspaceSettings = this.myWorkspace;
    }
  },
  mounted() {
    if (this.isOnDeconveHubWorkspace && this.modeRegister) {
      this.selectWorkspaceToRegisterPerson();
    }
  },
  beforeDestroy() {
    if (this.isOnDeconveHubWorkspace) this.fetchTags();
  },
  methods: {
    ...mapActions({
      addPerson: 'faceid/addPerson',
      loadPersonImage: 'faceid/loadPersonImage',
      addPersonProfileImage: 'faceid/addPersonProfileImage',
      addPersonFiles: 'faceid/addPersonFiles',
      addPersonVideos: 'faceid/addPersonVideos',
      fetchPerson: 'faceid/fetchPerson',
      fetchPersonIgnoringCachedData: 'faceid/fetchPersonIgnoringCachedData',
      fetchPersonImages: 'faceid/fetchPersonImages',
      fetchPersonFiles: 'faceid/fetchPersonFiles',
      fetchPersonVideos: 'faceid/fetchPersonVideos',
      getOriginalPersonImage: 'faceid/getOriginalPersonImage',
      fetchPersonProfileImage: 'faceid/fetchPersonProfileImage',
      editPerson: 'faceid/editPerson',
      editPersonImage: 'faceid/editPersonImage',
      editPersonFiles: 'faceid/editPersonFiles',
      editPersonVideos: 'faceid/editPersonVideos',
      deletePersonImage: 'faceid/deletePersonImage',
      deleteFaces: 'faceid/deleteFaces',
      detectFaces: 'faceid/detectFaces',
      selectFace: 'faceid/selectFace',
      resetPersonImages: 'faceid/resetPersonImages',
      resetPersonFiles: 'faceid/resetPersonFiles',
      resetPersonVideos: 'faceid/resetPersonVideos',
      resetPersonTags: 'faceid/resetPersonTags',
      resetPersonError: 'faceid/resetPersonError',
      sortPersonImages: 'faceid/sortPersonImages',
      fetchTags: 'tags/fetchTags',
      fetchWorkspace: 'workspace/fetchWorkspace',
    }),
    openFileSelection(inputId) {
      document.getElementById(inputId).click();
    },
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
      this.isSendingProfile = false;
    },
    handleDeleteFaces(index) {
      this.deleteFaces(index);
      this.wasImageEdited = true;
    },
    handleDetectFaces(index) {
      this.detectFaces(index);
      this.wasImageEdited = true;
    },
    handleSelectFace(index) {
      this.selectFace(index);
      this.wasImageEdited = true;
    },
    handleRemoveImage(index) {
      this.showImageCropper = false;

      if (this.personImages[index].image === this.profileImage) {
        this.addPersonProfileImage('');
      }

      URL.revokeObjectURL(this.personImages[index].image);
      this.deletePersonImage(index);
      const wasImageListEmpty = this.personImages.length === 0;

      if (wasImageListEmpty) {
        this.addPersonProfileImage('');
      }

      this.wasImageEdited = true;
    },
    removeFileFromCurrentList(fileName, fileList) {
      const index = fileList.findIndex((item) => item.name === fileName);
      const item = fileList[index];

      URL.revokeObjectURL(item.file);

      fileList.splice(index, 1);
    },
    handleDeleteFile(reportName) {
      if (this.isEditMode) {
        const index = this.personFiles.findIndex(
          (reportFile) => reportFile.originalName === reportName,
        );

        const isFileSaved = index >= 0;

        if (isFileSaved) {
          this.$refs.confirm.open(
            this.$t('deconve.removeFile'),
            this.$t('deconve.removeReportFile', [reportName]),
          ).then((confirm) => {
            if (confirm) {
              this.filesToBeDeleted.push(reportName);
              this.removeFileFromCurrentList(reportName, this.files);
            }
          });
        } else {
          this.removeFileFromCurrentList(reportName, this.files);
        }
      } else {
        this.removeFileFromCurrentList(reportName, this.files);
      }
    },
    handleDeleteVideo(videoName) {
      if (this.isEditMode) {
        const index = this.personVideos.findIndex((video) => video.originalName === videoName);

        const isVideoSaved = index >= 0;

        if (isVideoSaved) {
          this.$refs.confirm.open(
            this.$t('deconve.removeFile'),
            this.$t('deconve.removeVideoFile', [videoName]),
          ).then((confirm) => {
            if (confirm) {
              this.videosToBeDeleted.push(videoName);
              this.removeFileFromCurrentList(videoName, this.videos);
            }
          });
        } else {
          this.removeFileFromCurrentList(videoName, this.videos);
        }
      } else {
        this.removeFileFromCurrentList(videoName, this.videos);
      }
    },
    onCloseCropper({ imageData, index }) {
      if (imageData) {
        if (index >= 0) {
          this.editPersonImage({ imageData, fileExtension: '.png', imgIndex: index });
          this.wasImageEdited = true;
        } else {
          const { image } = imageData;

          this.addPersonProfileImage(image);
          this.showImageCropper = false;
        }
      } else {
        this.showImageCropper = false;
      }

      this.imageCropperKey += 1;
    },
    resetAllPersonFiles() {
      this.resetPersonImages();
      this.resetPersonFiles();
      this.resetPersonVideos();
      this.files.length = 0;
      this.videos.length = 0;
    },
    setCropImage(index) {
      this.cropIndex = index;
      this.showImageCropper = true;
    },
    updateCropIndex(index) {
      this.cropIndex = index;
    },
    openTagModal() {
      this.$refs.tagDialog.open(this.tags, true).then((response) => {
        if (response) {
          this.tags = response;
        }
      });
    },
    openUnitModal() {
      const unitId = this.unit?.id;

      this.$refs.unitDialog.open(unitId).then((selectedUnit) => {
        this.unit = selectedUnit;
        this.isFromUnknownUnit = !selectedUnit;
      }).catch(() => {
        // User canceled the dialog
      });
    },
    openRelatedProfilesModal() {
      this.$refs.profilesDialog.open(this.relatedProfiles).then((profiles) => {
        this.relatedProfiles = profiles;
      }).catch(() => {
        // User canceled the dialog
      });
    },
    getDefaultPersonTags() {
      const tags = this.personData?.tags;

      if (this.$route.params.personId && tags?.length > 0) {
        this.tags = [...tags];
      } else {
        this.tags = [];
      }
    },
    getDefaultPersonUnit() {
      const unitId = this.personData?.unit?.id;

      if (this.$route.params.personId && unitId) {
        this.unit = this.getUnit(unitId);
        this.isCurrentUnitIdValid = Boolean(this.unit?.id);
      } else if (this.$route.params.personId) {
        this.isFromUnknownUnit = true;
      }
    },
    getDefaultRelatedProfiles() {
      const profiles = this.personData?.related_profiles;

      if (this.$route.params.personId && profiles?.length > 0) {
        this.relatedProfiles = [...profiles].map((profile) => (profile.id));
      } else {
        this.relatedProfiles = [];
      }
    },
    setWorkspaceSettings(workspaceId) {
      this.fetchWorkspace(workspaceId).then((workspace) => {
        this.workspaceSettings = workspace;
        this.fetchTags(workspaceId);
      }).catch(() => {
        this.showAlert('warn', this.$t('deconve.couldNotFetchWorkspace', [workspaceId]));
      });
    },
    selectWorkspaceToRegisterPerson() {
      this.$refs.workspacesDialog.open({ scopes: [SCOPES.CREATE_COM_DECONVE_FACEID_PERSON] })
        .then((workspace) => {
          this.workspaceSettings = workspace;
          this.selectedWorkspaceId = workspace?.id;
          this.fetchTags(this.selectedWorkspaceId);
        }).catch(() => {
          // User did not select a workspace
        });
    },
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    reset() {
      this.$refs.form.reset();
      this.showImageCropper = false;
      this.resetAllPersonFiles();
      this.resetPersonTags();
      this.resetAlert();
      this.tags = [];
      this.isSendingProfile = false;
    },
    hasMoreImagesThanAllowed() {
      return this.personImagesNumber > this.imagesNumberLimit;
    },
    hasValidTags() {
      if (this.workspaceSettings?.is_person_tag_required) return this.tags.length > 0;

      return true;
    },
    hasNoFaceDetected() {
      const imageIndex = this.personImages.findIndex(
        (image) => image.info && image.info.faces.length > 0,
      );

      return imageIndex === -1;
    },
    hasImageWithUnselectedFace() {
      const imageIndex = this.personImages.findIndex(
        (image) => image.info && image.info.faces.length > 0 && image.info.selected === undefined,
      );

      return imageIndex >= 0;
    },
    hasFaceWithResolution(resolutionScoreType) {
      const imageIndex = this.personImages.findIndex((image) => {
        const { info } = image;

        if (!info || info.selected === undefined) {
          return false;
        }

        const selectedFace = info.faces[info.selected];

        const resolutionScore = imageResolutionScore(
          selectedFace.bounding_box.width,
          selectedFace.bounding_box.height,
        );

        if (resolutionScore === resolutionScoreType) {
          return true;
        }

        return false;
      });

      return imageIndex >= 0;
    },
    hasFaceWithQualityScore(qualityScoreType) {
      const imageIndex = this.personImages.findIndex((image) => {
        if (!image.info || image.info.selected === undefined) {
          return false;
        }

        const selectedFace = image.info.faces[image.info.selected];

        const qualityScore = imageQualityScore(selectedFace.image_quality_score * 100);

        if (qualityScore === qualityScoreType) {
          return true;
        }

        return false;
      });

      return imageIndex >= 0;
    },
    validationToSubmit() {
      this.resetAlert();
      this.resetPersonError();

      this.isSendingProfile = true;

      if (!this.hasValidTags()) {
        this.showAlert('warn', this.$t('deconve.person.alerts.noTagsSelected'));
        return;
      }

      if (this.hasMoreImagesThanAllowed()) {
        this.showAlert(
          'warn', this.$t('deconve.person.alerts.imagesNumberLimit', [this.imagesNumberLimit]),
        );
        return;
      }

      if (this.hasNoFaceDetected()) {
        this.showAlert('warn', this.$t('deconve.person.alerts.validationImage.noFaceDetected'));
        return;
      }

      if (this.hasImageWithUnselectedFace()) {
        this.showAlert('warn', this.$t('deconve.person.alerts.validationImage.noFaceSelected'));
        return;
      }

      if (this.hasFaceWithResolution(NOT_ACCEPTED_RESOLUTION_IMAGE)) {
        this.showAlert('warn', this.$t('deconve.person.alerts.validationImage.lowResolution'));
        return;
      }

      if (!this.unit && !this.isFromUnknownUnit) {
        this.showAlert('warn', this.$t('deconve.person.alerts.noUnitSelected'));
        return;
      }

      if (!this.isMonitoringAreaDelimitedByTags && this.isFromUnknownUnit) {
        this.showAlert('warn', this.$t('deconve.person.alerts.noUnitSelectedOnMonitoring'));
        return;
      }

      const lowResolutionFace = this.hasFaceWithResolution(LOW_RESOLUTION_IMAGE);
      const regularQualityFace = this.hasFaceWithQualityScore(REGULAR_QUALITY_IMAGE);
      const lowQualityFace = this.hasFaceWithQualityScore(LOW_QUALITY_IMAGE);

      let submitDialogMessage = null;

      if (lowResolutionFace && lowQualityFace) {
        submitDialogMessage = this.$t('deconve.person.submitDialog.lowResolutionAndLowQuality');
      } else if (lowResolutionFace && regularQualityFace) {
        submitDialogMessage = this.$t('deconve.person.submitDialog.lowResolutionAndRegularQuality');
      } else if (lowResolutionFace) {
        submitDialogMessage = this.$t('deconve.person.submitDialog.lowResolution');
      } else if (regularQualityFace) {
        submitDialogMessage = this.$t('deconve.person.submitDialog.regularQuality');
      } else if (lowQualityFace) {
        submitDialogMessage = this.$t('deconve.person.submitDialog.lowQuality');
      }

      if (this.isMonitoringAreaDelimitedByTags) {
        submitDialogMessage = this.$t('deconve.person.submitDialog.tagMonitoringAlert');
      }

      if (submitDialogMessage) {
        this.$refs.confirm.open(
          this.$t('deconve.person.submitDialog.title'),
          submitDialogMessage,
        ).then((confirm) => {
          if (confirm) {
            this.submit();
          } else {
            this.isSendingProfile = false;
          }
        });
      } else {
        this.submit();
      }
    },
    prepareImagesToSubmit() {
      return new Promise((resolve) => {
        if (!this.wasImageEdited) {
          resolve([]);
          return;
        }

        const getOriginalImagePromises = [];

        this.personImages.forEach((img, index) => {
          if (img.originalImage === '') {
            getOriginalImagePromises.push(this.getOriginalPersonImage(index));
          }
        });

        Promise.all(getOriginalImagePromises).then(() => {
          const imageToBlobPromises = [];

          this.personImages.forEach((img) => {
            imageToBlobPromises.push(
              dataUrlToBlob(img.originalImage).then((blob) => ({
                name: img.originalName,
                image: blob,
                info: img.info,
              })),
            );
          });

          Promise.all(imageToBlobPromises).then((files) => resolve(files));
        });
      });
    },
    prepareFilesToSubmit() {
      return new Promise((resolve) => {
        const files = [];

        this.files.forEach(({ name, file }) => {
          if (file) {
            files.push({ name, file });
          }
        });

        resolve(files);
      });
    },
    prepareVideosToSubmit() {
      return new Promise((resolve) => {
        const videos = [];

        this.videos.forEach(({ name, file }) => {
          if (file) {
            videos.push({ name, file });
          }
        });

        resolve(videos);
      });
    },
    prepareImageFilesAndVideosToSubmit() {
      return Promise.all([
        dataUrlToBlob(this.profileImage),
        this.prepareImagesToSubmit(),
        this.prepareFilesToSubmit(),
        this.prepareVideosToSubmit(),
      ]);
    },
    submit() {
      this.isSendingProfile = true;
      const formData = new FormData();

      this.prepareImageFilesAndVideosToSubmit().then(
        ([profileImage, images, files, videos]) => {
          images.forEach((imageFileInfo) => {
            formData.append('images', imageFileInfo.image, imageFileInfo.name);

            if (imageFileInfo.info && imageFileInfo.info.selected >= 0) {
              const faceInfo = JSON.stringify({
                image: imageFileInfo.name,
                faces: [imageFileInfo.info.faces[imageFileInfo.info.selected]],
              });

              formData.append('image_face_infos', faceInfo);
            }
          });

          formData.append('name', this.name);
          formData.append('about', this.about || '');
          formData.append('vehicle_license_plate', this.vehicleLicensePlate || '');
          formData.append('is_monitoring_area_delimited_by_tags', this.isMonitoringAreaDelimitedByTags);
          formData.append('profile_image', profileImage, 'profile_image.jpg');

          if (this.tags.length > 0) {
            this.tags.forEach((tag) => formData.append('tag_ids', tag.id));
          } else {
            formData.append('tag_ids', '');
          }

          if (this.unit && this.unit?.id) {
            const { id: unitId } = this.unit;

            formData.append('unit_id', unitId);
          } else {
            formData.append('unit_id', '');
          }

          if (this.relatedProfiles.length > 0) {
            this.relatedProfiles.forEach((profileId) => {
              formData.append('related_profiles', JSON.stringify({ id: profileId }));
            });
          } else {
            formData.append('related_profiles', '');
          }

          this.alert = true;

          if (this.isEditMode) {
            const editPromises = [
              this.editPersonFiles({
                personId: this.personId,
                files,
                filesToBeDeleted: this.filesToBeDeleted,
              }),
              this.editPersonVideos({
                personId: this.personId,
                videos,
                videosToBeDeleted: this.videosToBeDeleted,
              }),
              this.editPerson({ personId: this.personId, payload: formData }),
            ];

            Promise.all(editPromises).then(() => {
              this.isSendingProfile = false;
              this.fetchPersonIgnoringCachedData(this.personId).then(() => {
                this.$emit('edited');
              });
            }).catch((error) => {
              this.error = error;
              this.isSendingProfile = false;
            });
          } else {
            const personData = { payload: formData };

            if (this.isOnDeconveHubWorkspace && this.selectedWorkspaceId) {
              personData.workspaceId = this.selectedWorkspaceId;
            }

            this.addPerson(personData).then((personId) => {
              const addPromises = [
                this.addPersonFiles({ personId, files }),
                this.addPersonVideos({ personId, videos }),
              ];

              Promise.all(addPromises).then(() => {
                this.isSendingProfile = false;

                this.fetchPersonIgnoringCachedData(personId).then(() => {
                  this.$emit('registered', personId);
                });
              });
            }).catch((error) => {
              this.error = error;
              this.isSendingProfile = false;
            });
          }
        },
      );
    },
    cancelEdit() {
      this.resetAllPersonFiles();
      this.resetPersonTags();
      this.getDefaultPersonTags();
      this.$emit('canceled');
    },
    removeFileNameExtension(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    loadImages(files) {
      const hasSpaceToNewImages = this.personImagesNumber + files.length <= this.imagesNumberLimit;

      if (hasSpaceToNewImages) {
        const imagesAlreadyAdded = [];
        const promises = [];

        Array.from(files).forEach((file) => {
          promises.push(
            this.loadPersonImage(file).then((isNew) => {
              if (isNew) {
                this.wasImageEdited = true;
              } else {
                // Image name without the extension must be unique
                imagesAlreadyAdded.push(this.removeFileNameExtension(file.name));
              }
            }),
          );
        });

        Promise.all(promises).then(() => {
          if (imagesAlreadyAdded.length > 0) {
            this.showAlert(
              'info',
              this.$t('deconve.person.alerts.imageAlreadyAdded', [imagesAlreadyAdded.join(', ')]),
            );
          }
        });
      } else {
        this.showAlert(
          'warn', this.$t('deconve.person.alerts.imagesNumberLimit', [this.imagesNumberLimit]),
        );
      }
    },
    isFileSizeAccepted(files, maxFileSizeAllowedInBytes) {
      const index = files.findIndex((file) => file.size > maxFileSizeAllowedInBytes);

      return index < 0;
    },
    isFileNameSizeAccepted(files, maxFileNameSize) {
      const index = files.findIndex((file) => file.name.length > maxFileNameSize);

      return index < 0;
    },
    loadFilesHelper(files, currentFiles) {
      const maxFileNameSize = 128;
      const maxFileSizeAllowedInBytes = 20971520; // 20 MB
      const maxNumberOfFilesAllowed = 3;

      const filesArray = Array.from(files);

      if (!this.isFileSizeAccepted(filesArray, maxFileSizeAllowedInBytes)) {
        this.showAlert('warn', this.$t('deconve.person.alerts.fileSizeTooBig'));
        return;
      }

      if (!this.isFileNameSizeAccepted(filesArray, maxFileNameSize)) {
        this.showAlert('warn', this.$t('deconve.person.alerts.fileNameTooLong'));
        return;
      }

      const numberOfFiles = currentFiles.length + filesArray.length;

      if (numberOfFiles > maxNumberOfFilesAllowed) {
        this.showAlert(
          'warn', this.$t('deconve.person.alerts.filesNumberLimit', [maxNumberOfFilesAllowed]),
        );
        return;
      }

      const filesAlreadyAdded = [];

      filesArray.forEach((file) => {
        const index = currentFiles.findIndex(({ name }) => name === file.name);
        const isANewFile = index < 0;

        if (isANewFile) {
          currentFiles.push({ name: file.name, file: URL.createObjectURL(file) });
        } else {
          filesAlreadyAdded.push(file.name);
        }
      });

      if (filesAlreadyAdded.length > 0) {
        this.showAlert(
          'info',
          this.$t('deconve.person.alerts.fileAlreadyAdded', [filesAlreadyAdded.join(', ')]),
        );
      }
    },
    loadFiles(files) {
      this.loadFilesHelper(files, this.files);
    },
    loadVideoFiles(files) {
      this.loadFilesHelper(files, this.videos);
    },
    initEdit(person) {
      const {
        name,
        about,
        created_at: createdAt,
        updated_at: updatedAt,
        vehicle_license_plate: vehicleLicensePlate,
        is_monitoring_area_delimited_by_tags: isMonitoringAreaDelimitedByTags,
      } = person;

      this.name = name || '';
      this.about = about || '';
      this.createdAt = createdAt || '';
      this.updatedAt = updatedAt || '';
      this.vehicleLicensePlate = vehicleLicensePlate || '';
      this.isMonitoringAreaDelimitedByTags = Boolean(isMonitoringAreaDelimitedByTags);

      if (this.personId) {
        const { profile_image_url: profileImageUrl } = person;

        this.fetchPersonProfileImage({ profileImageUrl, personId: this.personId });

        this.fetchPersonImages({
          images: person.images,
          personId: this.personId,
        });

        this.fetchPersonFiles({
          files: person.files,
          personId: this.personId,
        }).then(() => {
          this.files = [];
          this.personFiles.forEach(
            ({ originalName }) => this.files.push({ name: originalName }),
          );
        });

        this.fetchPersonVideos({
          videos: person.videos,
          personId: this.personId,
        }).then(() => {
          this.videos = [];
          this.personVideos.forEach(
            ({ originalName }) => this.videos.push({ name: originalName }),
          );
        });
      } else {
        this.resetAllPersonFiles();
      }
    },
  },
};
</script>
